<template>
  <div class="content">
    <div class="top">
      <el-row type="flex">
        <el-col class="title">總體數據</el-col>
        <el-col v-for="(item, index) in topData" :key="index">
          <el-row>
            <el-col class="info">{{ item.info }}</el-col>
            <el-col class="data_num">{{ item.data_num }}</el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="data_board">
      <div class="data_title">
        <div class="title">數據趨勢</div>
        <div class="right_box">
          <el-radio-group size="small" v-model="type">
            <el-radio-button label="day">日</el-radio-button>
            <el-radio-button label="month">月</el-radio-button>
          </el-radio-group>
          <div class="input_item">
            <el-select
              size="small"
              filterable
              v-model="shop_id"
              placeholder="請選擇店鋪"
              clearable
            >
              <el-option
                v-for="item in shopList"
                :key="item.value"
                :label="item.shop_name"
                :value="item.shop_id"
              >
              </el-option>
            </el-select>
          </div>
          <el-date-picker
            v-if="type === 'day'"
            size="small"
            v-model="searchData"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="選擇開始日期"
            end-placeholder="選擇結束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
          >
            <!-- value-format="timestamp" -->
          </el-date-picker>
          <el-date-picker
            v-else
            size="small"
            v-model="searchData"
            type="monthrange"
            align="right"
            unlink-panels
            range-separator="-"
            start-placeholder="選擇開始月份"
            end-placeholder="選擇結束月份"
            :picker-options="monthpickerOptions"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
      <chartLine
        :type="type"
        :shop_id="shop_id"
        :searchData="searchData"
        @childData="handleChildData"
      ></chartLine>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import chartLine from "./components/chartLine .vue";
import { listShop } from "@/api/shop";
export default {
  name: "dataBoard",
  components: { chartLine },
  data() {
    return {
      topData: [
        {
          info: "總銷售額",
          data_num: "",
        },
        {
          info: "總利潤",
          data_num: "",
        },
        {
          info: "訂單量",
          data_num: "",
        },
        {
          info: "商品銷量",
          data_num: "",
        },
      ],
      shopList: [],
      shop_id: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近7天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近1个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近半年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      monthpickerOptions: {
        shortcuts: [
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近十二个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth());
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      searchData: "",
      type: "month",
    };
  },

  created() {
    this.getAllListShop();
    this.setDate();
  },
  //   watch: {
  //     searchData(val) {
  //       console.log(val, 666);
  //     },
  //   },
  methods: {
    // 获取所有店铺
    async getAllListShop() {
      try {
        const { data } = await listShop();
        this.shopList = data.list;
      } catch (e) {
        console.log(e);
      }
    },
    handleChildData(data) {
      if (data) {
        this.topData[0].data_num = data.sumPrice;
        this.topData[1].data_num = data.sumProfit;
        this.topData[2].data_num = data.sumOrder;
        this.topData[3].data_num = data.sumGoods;
      }
    },
    // 初始时间
    setDate() {
      const today = new Date(); // 当前日期
      const startOfTwelveMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 12,
        today.getDate()
      ); // 12个月前的日期
      const start_time = dayjs(startOfTwelveMonthsAgo).format("YYYY-MM-DD");
      const end_time = dayjs(today).format("YYYY-MM-DD");
      this.searchData = [start_time, end_time];
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px;
  .top {
    background-color: #fff;
    padding: 20px;
    .info {
      font-size: 12px;
      color: #6f6f6f;
      margin-bottom: 10px;
    }
    .data_num {
      color: black;
      font-weight: 500;
      font-size: 20px;
    }
  }
  .title {
    font-weight: 700;
    font-size: 14px;
  }
  .data_board {
    background-color: #fff;
    padding: 20px;
    margin-top: 20px;
    .data_title {
      display: flex;
      justify-content: space-between;
    }
    .right_box {
      display: flex;
      ::v-deep .el-input__inner {
        width: 220px;
      }
      .input_item {
        margin: 0 20px;
      }
    }
  }
}
</style>
