<template>
  <div>
    <div id="lineChart"></div>
  </div>
</template>

<script>
import { getSalesRanking } from "@/api/goodsSellTotal";
export default {
  data() {
    return {
      totalData: "",
      dateList: [],
    };
  },
  props: ["shop_id", "searchData", "type"],

  created() {
    this.getSalesRankingList();
  },
  mounted() {
    // this.initLineChart();
  },
  watch: {
    shop_id(val) {
      this.getSalesRankingList();
    },
    searchData(val) {
      //   this.setType(val);
      this.getSalesRankingList();
    },
    type() {
      this.getSalesRankingList();
    },
  },
  methods: {
    // 折线图
    initLineChart() {
      let lineChart = document.getElementById("lineChart");
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(lineChart);
      const option = {
        // title: {
        //   text: "總銷售額/利潤趋势图",
        //   left: "center",
        //   textStyle: {
        //     fontSize: 14,
        //   },
        // },
        tooltip: {
          textStyle: {
            color: "balck", // 设置文字颜色为黑色
          },
          borderWidth: 1, // 设置边框宽度为1像素
          borderColor: "#999999", // 设置边框颜色为灰色
          backgroundColor: "#fff", // 设置背景颜色为白色
          trigger: "axis",
          axisPointer: {
            type: "cross", // 设置 axisPointer 类型为十字准星型
            lineStyle: {
              type: "dashed", // 设置虚线样式
            },
          },
        },
        legend: {
          data: ["總銷售額", "利潤", "銷量"],
          top: "0px",
          left: "10px",
          icon: "circle",
          itemGap: 20,
          formatter: function (name) {
            var unit = ["元", "元", "個"]; // 每条线对应的单位
            var index = option.series.findIndex(function (series) {
              return series.name === name;
            });

            return name + " (" + unit[index] + ")"; // 在图例文本后面拼接单位
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.totalData.month,
          axisLine: {
            lineStyle: {
              width: 1,
              // color: "rgb(168,168,168)",
              //   color: "black",
            },
          },
        },
        yAxis: {
          //   name: "單位/元",
          type: "value",
          boundaryGap: [0, "50%"],
          axisLine: {
            show: false,
            lineStyle: {
              width: 2,
              // color: "rgb(168,168,168)",
              color: "black",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgb(168,168,168)",
            },
          },
          axisTick: {
            show: false, // 隐藏 Y 轴刻度线
          },
        },
        grid: {
          top: "80px",
          bottom: "30px",
          left: "80px",
          right: "50px",
        },
        series: [
          {
            data: this.totalData.total_price,
            name: "總銷售額",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#988de8",
            },
            // areaStyle: {
            //   color: {
            //     type: "linear",
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [
            //       {
            //         offset: 0,
            //         color: "rgba(255,0,0,.5)",
            //       },
            //       {
            //         offset: 0.7,
            //         color: "rgba(255,0,0,0)",
            //       },
            //     ],
            //   },
            // },
          },
          {
            data: this.totalData.profit,
            name: "利潤",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#91b0ff",
            },
            // areaStyle: {
            //   color: {
            //     type: "linear",
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [
            //       {
            //         offset: 0,
            //         color: "rgba(159, 193, 255,.5)",
            //       },
            //       {
            //         offset: 0.7,
            //         color: "rgba(159, 193, 255,0)",
            //       },
            //     ],
            //   },
            // },
          },
          {
            data: this.totalData.total_num,
            name: "銷量",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#c4d95d",
            },
            // areaStyle: {
            //   color: {
            //     type: "linear",
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [
            //       {
            //         offset: 0,
            //         color: "rgba(159, 193, 255,.5)",
            //       },
            //       {
            //         offset: 0.7,
            //         color: "rgba(159, 193, 255,0)",
            //       },
            //     ],
            //   },
            // },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    async getSalesRankingList() {
      try {
        const { data } = await getSalesRanking({
          type: this.type,
          shop_id: this.shop_id,
          start_time: this.searchData[0],
          end_time: this.searchData[1],
        });
        this.totalData = data;
        this.sendData();
        this.initLineChart();
      } catch (e) {
        console.log(e);
      }
    },
    sendData() {
      this.$emit("childData", this.totalData);
    },
    // 判断type
    // setType(val) {
    //   const days = (val[1] - val[0]) / (24 * 60 * 60 * 1000).toFixed(2);
    //   days < 180 ? (this.type = "day") : (this.type = "month");
    //   this.dateList[0] = dayjs(val[0]).format("YYYY-MM-DD");
    //   this.dateList[1] = dayjs(val[1]).format("YYYY-MM-DD");
    // },
  },
};
</script>

<style lang="scss" scoped>
#lineChart {
  width: 100%;
  height: 500px;
  margin-top: 10px;
}
</style>
