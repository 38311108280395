import http from "../utils/request"


//查询店铺列表
export function listShop(data) {
    return http({
        url: '/admin/shop/list',
        method: 'post',
        data: data
    })
}

//编辑店铺开关
export function editShop(data) {
    return http({
        url: '/admin/shop/edit',
        method: 'post',
        data: data
    })
}
//编辑店铺门开
export function openDoor(data) {
    return http({
        url: '/admin/shop/openDoor',
        method: 'post',
        data: data
    })
}



