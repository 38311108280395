import http from "../utils/request";

//商品销量;
export const getGoodsSellTotal = (data) => {
  return http({
    url: "/admin/analy/goods",
    method: "post",
    data,
  });
};

// 店铺销量
export const getShopSellTotal = (data) => {
  return http({
    url: "/admin/analy/shop",
    method: "post",
    data,
  });
};

// 销量排行
export const getSalesRanking = (data) => {
  return http({
    url: "/admin/analy/month",
    method: "post",
    data,
  });
};
